import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// images
import background from "../../assets/img/bg-01.jpg";
import axios from "axios";
import { setAccessToken } from "../../utils/Token";

const Login = () => {

  const Navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(true)
  const [formData, setFormData] = useState({
    username: '',
    password: ''
  });
  const [error, setError] = useState({
    error: true,
    msg: ''
  });


  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setError({ error: false, msg: '' });
      const { username, password } = formData;
      let response = await axios.post('https://emrtest.genensys.com/clearinghouse/login', {
        "user": username,
        "password": password,
      })
      if (response?.status === 200 && response?.data?.token !== null) {
        setAccessToken(response?.data?.token);
        Navigate('/upload-history')
      } else {
        setError({ error: true, msg: 'Invalid User and Password!' });
      }
    } catch (error) {
      setError({ error: true, msg: 'Invalid User and Password!' });
      console.log(error);
    }

  }


  return (
    <div className="limiter">
      <div
        className="container-login100"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="wrap-login100">
          <form className="login100-form validate-form" onSubmit={handleSubmit}>
            <span className="login100-form-logo">
              <i className="zmdi zmdi-landscape"></i>
            </span>

            <span className="login100-form-title p-b-34 p-t-27">Log in</span>

            <div
              className="wrap-input100 validate-input"
              data-validate="Enter username"
            >
              <input
                className="input100"
                type="text"
                name="username"
                placeholder="Username"
                onChange={(e) => setFormData({ ...formData, username: e.target.value })}
              />
              <span
                className="focus-input100"
                data-placeholder="&#xf207;"
              >
                <i className="fa fa-user"></i>
              </span>
            </div>

            <div
              className="wrap-input100 validate-input pos-relative"
              data-validate="Enter password"
            >
              <input
                className="input100"
                type={showPassword ? "password" : "text"}
                name="pass"
                placeholder="Password"
                onChange={(e) => setFormData({ ...formData, password: e.target.value })}
              />
              <span
                className="focus-input100"
                data-placeholder="&#xf191;"
              >
                <i className="fa fa-lock"></i>
              </span>
              <i onClick={() => setShowPassword(!showPassword)} className={`${showPassword ? 'fa-eye-slash' : 'fa-eye'} fa pos-absolute right-0 top-0 m-t-15 m-r-5 text-white hov-pointer`} ></i>
            </div>

            <div className="contact100-form-checkbox">
              <input
                className="input-checkbox100"
                id="ckb1"
                type="checkbox"
                name="remember-me"
              />
              <label className="label-checkbox100" htmlFor="ckb1">
                Remember me
              </label>
            </div>

            <div className="contact100-form-checkbox">
              <span>{error.msg}</span>
            </div>

            <div className="container-login100-form-btn">
              <button
                className="login100-form-btn"
                type="submit"
              >
                Login
              </button>
            </div>

            <div className="text-center my-2">
              <a className="txt1">Forgot Password?</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
