import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { getAccessToken } from "../../utils/Token";

const ClaimHistory = () => {

    let { id } = useParams();
    const [apiResData, setApiResData] = useState([]);

    useEffect(() => {
        getDataByApi(id);
    }, [id]);

    const getDataByApi = async (id) => {
        try {
            const response = await axios.post(
                `https://emrtest.genensys.com/clearinghouse/claimstatus?claimId=${id}`,
                {}, // Pass request body here (empty object if no body is required)
                {
                    headers: {
                        'Authorization': getAccessToken(),
                        'Content-Type': 'application/json', // Optional, add if the server expects JSON
                    },
                }
            );
            const data = await response?.data?.claimData?.toLocaleString().split('\n');
            setApiResData(data)
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="p-l-10 p-r-10 p-t-20">
            <h1>Claims Data</h1>
            <div className="claim-data-container">
                <div className="claim-data-textbox">
                    {apiResData.length > 0 && apiResData.map((data, index) => {
                        return <p className="mb-0" key={index}>{data}</p>
                    })}
                </div>
            </div>
        </div>
    );
};

export default ClaimHistory;
