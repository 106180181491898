import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

// Utils
import { getAccessToken } from '../utils/Token';
// Utils

// Components
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import AutoLogout from '../components/AutoLogout';
// Components

const Private = ({ children }) => {
    const navigate = useNavigate();
    let token = getAccessToken();

    useEffect(() => {
        if (!token) {
            navigate("/login", { replace: true });
        }
    }, [token]);

    return (
        <>
            <AutoLogout />
            <Header />
            <Sidebar />
            <div className='content-wrapper'>
                <div className='content-wrapper-inner'>
                    {children}
                </div>
            </div>
        </>
    );
}

export default Private