import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { getAccessToken } from "../../utils/Token";

const FileHistory = () => {

  let { id } = useParams();
  const [apiResData, setApiResData] = useState('');

  useEffect(() => {
    getDataByApi(id);
  }, [id]);

  const getDataByApi = async (id) => {
    try {
      const response = await axios.post(
        `https://emrtest.genensys.com/clearinghouse/searchclaim?fileId=${id}`,
        {}, // Pass request body here (empty object if no body is required)
        {
          headers: {
            'Authorization': getAccessToken(),
            'Content-Type': 'application/json', // Optional, add if the server expects JSON
          },
        }
      );
      if (response?.data?.data.length > 0) {
        setApiResData(response?.data?.data);
      } else {
        setApiResData([]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="">
      <h1 className="mb-4">File History</h1>

      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>File ID</th>
              <th>Client ID</th>
              <th>Claim ID</th>
              <th>Claim Status</th>
              <th>Date of Service</th>
              <th>Amt</th>
              <th>Patient ID</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Insurance ID</th>
              <th>Payer Name</th>
            </tr>
          </thead>
          <tbody>
            {
              apiResData.length > 0 && apiResData.map((item, index) => (
                <tr key={index}>
                  <td>{item.fileId}</td>
                  <td>{item.clientId}</td>
                  <td><Link className="text-primary" to={`/claim-history/${item.claimId}`}>{item.claimId}</Link></td>
                  {/* <td>{item.claimId}</td> */}
                  <td>{item.claimStatus}</td>
                  <td>{item.dateOfService}</td>
                  <td>{item.amt}</td>
                  <td>{item.id}</td>
                  <td>{item.firstName}</td>
                  <td>{item.lastName}</td>
                  <td>{item.patientId}</td>
                  <td>{item.payerName}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FileHistory;
