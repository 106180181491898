import React from "react";
import { useNavigate } from "react-router-dom";
// images
import user from "../assets/img/user.png";
import { Dropdown } from "react-bootstrap";
const Header = () => {

  let navigate = useNavigate();
  const signout = () => {
    localStorage.removeItem("token");
    navigate("/");
    window.location.reload();
  };

  return (
    <div className="header-wrapper">
      <div className="">
        <Dropdown>
          <Dropdown.Toggle variant="transparent" className="btn" id="dropdown-basic">
            <img className="avatar" src={user} alt="" />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href="#/action-2">Settings</Dropdown.Item>
            <Dropdown.Item onClick={signout}>Sign Out</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default Header;
