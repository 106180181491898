import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
// import { Modal } from "react-bootstrap";
import { getAccessToken } from "../../utils/Token";

const UploadHistory = () => {

  const navigate = useNavigate();

  const [apiResData, setApiResData] = useState('');
  // const [fileContent, setFileContent] = useState([]);
  // const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    startDate: '',
    endDate: ''
  });

  const getCurrentAndPreviousWeekDates = () => {
    const currentDate = new Date();
    const previousWeekDate = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000); // Subtract 7 days in milliseconds

    // Format date to YYYY-MM-DD
    const formatDate = (date) =>
      date.toISOString().split('T')[0]; // Extract YYYY-MM-DD from ISO string

    return {
      currentDate: formatDate(currentDate),
      previousWeekDate: formatDate(previousWeekDate),
    };
  }

  useEffect(() => {
    // Example usage
    const dates = getCurrentAndPreviousWeekDates();
    // console.log("Current Date:", dates.currentDate);
    // console.log("7 Days Before Date:", dates.previousWeekDate);
    getDataByApi(dates.previousWeekDate, dates.currentDate);
  }, []);

  const getDataByApi = async (startDate, endDate) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `https://emrtest.genensys.com/clearinghouse/searchfile?startDate=${startDate}&endDate=${endDate}`,
        {}, // Pass request body here (empty object if no body is required)
        {
          headers: {
            'Authorization': getAccessToken(),
            'Content-Type': 'application/json', // Optional, add if the server expects JSON
          },
        }
      );
      if (response?.data?.data.length > 0) {
        setApiResData(response?.data?.data);
        setLoading(false);
      } else {
        setApiResData([]);
        setLoading(false);
      }
      console.log(response?.data?.data)
    } catch (error) {
      console.log(error);
    }
  }

  const handleSubmit = () => {
    getDataByApi(formData.startDate, formData.endDate);
  }

  const seeFileContent = (id, content) => {
    const data = content?.toLocaleString()?.split('\n');
    navigate(`/file-content/${id}`, { state: data });
    // setFileContent(data);
    // setShowModal(true);
  }

  // const closeModal = () => {
  //   setFileContent([]);
  //   setShowModal(false);
  // }



  return (
    <>
      <div className="">
        <h1 className="mb-4">Upload History</h1>

        <div className="mb-4 d-flex">
          <div className="form-field flex-shrink-0 me-4">
            <label className="fw-bold mb-2">Start Date</label>
            <input type="date" className="form-control" defaultValue="" name="startDate" onChange={(e) => setFormData({ ...formData, startDate: e.target.value })} />
          </div>
          <div className="form-field flex-shrink-0">
            <label className="fw-bold mb-2">End Date</label>
            <input type="date" className="form-control" defaultValue="" name="endDate" onChange={(e) => setFormData({ ...formData, endDate: e.target.value })} />
          </div>
          <div className="ms-4 form-field flex-shrink-0 align-self-end">
            <button disabled={(formData.startDate === "" && formData.endDate === "") ? true : false} onClick={handleSubmit} className="btn btn-primary">Submit</button>
          </div>
        </div>

        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>File ID</th>
                <th>Client ID</th>
                <th>File Name</th>
                <th>File Status</th>
                <th>Processed</th>
                <th>Processed Time</th>
                <th>Batch Number</th>
                <th>Total Claims</th>
                <th>Total Errors</th>
                <th>Uploaded Time</th>
                <th>File Content</th>
              </tr>
            </thead>
            <tbody>
              {
                (!loading && apiResData.length > 0) && apiResData.map((item, index) => (
                  <tr key={index}>
                    <td><Link className="text-primary" to={`/file-history/${item.fileId}`}>{item.fileId}</Link></td>
                    <td>{item.clientId}</td>
                    <td>{item.fileName}</td>
                    <td>{item.fileStatus}</td>
                    <td>{item.processed}</td>
                    <td>{item.processedTime}</td>
                    <td>{item.batchNumber}</td>
                    <td>{item.totalClaims}</td>
                    <td>{item.totalErrors}</td>
                    <td>{item.uploadedTime}</td>
                    <td>
                      <div className="form-field">
                        <button className="btn btn-sm btn-primary" onClick={() => seeFileContent(item.fileId, item.fileContents)}>See More</button>
                      </div>
                    </td>
                  </tr>
                ))
              }
              {
                loading && (
                  <tr>
                    <td colSpan={12} className="text-center">
                      <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </td>
                  </tr>
                )
              }
              {
                (!loading && apiResData.length === 0) && (
                  <tr>
                    <td colSpan={12} className="text-center">No Record Found</td>
                  </tr>
                )
              }
            </tbody>
          </table>
        </div>
      </div>


      {/* <Modal
        show={showModal}
        centered
        className="invite-success-modal success-modal"
        backdrop="static"
      >
        <Modal.Body>
          <div className="p-5 pt-2 px-4">
            <div className="text-end pb-3">
              <button className="closeButton notButton ms-auto" onClick={closeModal}>
                <i className="zmdi zmdi-close"></i>
              </button>
            </div>
            {fileContent.length > 0 && fileContent.map((data, index) => {
              return <small className="mb-0" style={{ wordBreak: "break-word" }} key={index}>{data}</small>
            })}
          </div>
        </Modal.Body>
      </Modal> */}
    </>

  );
};

export default UploadHistory;
