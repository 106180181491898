import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

const AutoLogout = () => {
    const navigate = useNavigate();
    const timeoutRef = useRef(null);

    // Duration (e.g., 5 minutes of inactivity)
    const INACTIVITY_TIME = 5 * 60 * 1000;

    const resetTimeout = () => {
        // Clear the existing timer
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        // Start a new timer
        timeoutRef.current = setTimeout(() => {
            // Remove token from local storage
            localStorage.removeItem("token");
            navigate('/')
            // console.log("Token removed due to inactivity.");
            // Optionally, you can redirect or trigger additional actions here
        }, INACTIVITY_TIME);
    };

    useEffect(() => {
        // Add event listeners for mouse and keyboard activity
        const handleActivity = () => resetTimeout();

        window.addEventListener("mousemove", handleActivity);
        window.addEventListener("keydown", handleActivity);

        // Initialize the timeout when the component mounts
        resetTimeout();

        // Cleanup event listeners and timeout when the component unmounts
        return () => {
            window.removeEventListener("mousemove", handleActivity);
            window.removeEventListener("keydown", handleActivity);
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    return null; // This component doesn't render anything
};

export default AutoLogout;