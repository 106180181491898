import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
// REDUX
import { Provider } from 'react-redux';
import store from './redux/store';
// CSS
import './assets/css/util.css'
import './assets/css/main.css'
import './assets/fonts/iconic/css/material-design-iconic-font.min.css'
import './assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css'
import './assets/fonts/fontawesome.css'
import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
      <App />
  </Provider>,
);

reportWebVitals();
