import React, { useEffect } from 'react'
import { getAccessToken } from '../utils/Token';
import { useNavigate } from 'react-router-dom';

const Public = ({ children }) => {
    const navigate = useNavigate();
    let token = getAccessToken();

    useEffect(() => {
        if (token) {
            navigate("/upload-history", { replace: true });
        }
    }, [token]);

    return (
        <>
            {children}
        </>
    );
}

export default Public;