import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const FileContent = () => {
    const location = useLocation();
    const [apiResData, setApiResData] = useState([]);

    useEffect(() => {
        if (location.state) {
            setApiResData(location.state);
        }
    }, [location.state]);

    return (
        <div className="p-l-10 p-r-10 p-t-20">
            <h1>File Content Data</h1>
            <div className="claim-data-container">
                <div className="claim-data-textbox">
                    {apiResData.length > 0 && apiResData.map((data, index) => {
                        return <p className="mb-0" key={index}>{data}</p>
                    })}
                </div>
            </div>
        </div>
    )
}

export default FileContent